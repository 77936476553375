import shallowEqual from '../../utils/objectCompare';
import { groupBy } from 'lodash';
import {
  ADD_MESSAGE,
  SEND_SUCCESS,
  GET_PREDEFINE,
  DELETE_MESSAGE,
  SELECT_MESSAGE,
  GET_HSM,
  ADD_HSM,
  SEND_SUCCESS_HSM,
  DELETE_HSM,
  UPLOAD_FILE,
  LOADING_MEDIA,
  LOADING_TEMPLATES,
  UPDATE_TEMPLATE,
} from "./types";

interface IntialState {
  predefineMessages: [];
  templateMessages: [];
  selectedMessage: "";
  newTemplateMedia: {};
  predefineCategories: [];
  groupedPredefine: {};
  loadingMedia: boolean;
}

const initialState: IntialState = {
  predefineMessages: [],
  templateMessages: [],
  selectedMessage: "",
  newTemplateMedia: {},
  predefineCategories: [],
  groupedPredefine: {},
  loadingMedia: false,
};

export default function predefineReducer(
  state = initialState,
  action: { type: any; payload: any }
) {
  const { type, payload } = action;
  switch (type) {
    case GET_PREDEFINE:
      return [...state.predefineMessages];
    case ADD_MESSAGE:
      const newMessage =
        payload.length >= state.predefineMessages.length &&
        !shallowEqual(state.predefineMessages, payload)
          ? [...payload]
          : [...state.predefineMessages, ...payload];
      const categorySet = new Set();
      newMessage.forEach((item: any) => {
        categorySet.add(item.category);
      });
      return {
        ...state,
        predefineMessages: newMessage,
        groupedPredefine: groupBy(newMessage, "category"),
        predefineCategories: [
          ...[...categorySet].map((item) => {
            return { text: item, value: item };
          }),
        ],
      };
    case SEND_SUCCESS:
      state.predefineMessages.map((item: any, index: number) => {
        if (
          index === state.predefineMessages.length - 1 &&
          item.hasOwnProperty("sending")
        ) {
          item.sending = payload.sending;
          return item;
        }
        return item;
      });
      return {
        ...state,
        predefineMessages: [...state.predefineMessages],
      };
    case DELETE_MESSAGE: {
      const newMessage = state.predefineMessages.filter(
        (msg: any) => msg.id !== payload
      );
      return {
        ...state,
        groupedPredefine: groupBy(newMessage, "category"),
        predefineMessages: [...newMessage],
      };
    }
    case SELECT_MESSAGE:
      return {
        ...state,
        selectedMessage: payload,
      };
    /* HSM TEMPLATE */
    case GET_HSM:
      return [...state.templateMessages];
    case ADD_HSM:
      if (
        payload.length >= state.templateMessages.length &&
        !shallowEqual(state.templateMessages, payload)
      ) {
        return {
          ...state,
          templateMessages: [...payload],
        };
      }
      return {
        ...state,
        templateMessages: [...state.templateMessages, ...payload],
      };
    case SEND_SUCCESS_HSM:
      state.templateMessages.map((item: any, index: number) => {
        if (
          index === state.templateMessages.length - 1 &&
          item.hasOwnProperty("sending")
        ) {
          item.sending = payload.sending;
          return item;
        }
        return item;
      });
      return {
        ...state,
        templateMessages: [...state.templateMessages],
      };
    case DELETE_HSM:
      return {
        ...state,
        templateMessages: [
          ...state.templateMessages.filter((msg: any) => msg.id !== payload),
        ],
      };
    case UPDATE_TEMPLATE:
      return {
        ...state,
        templateMessages: [
          ...state.templateMessages.map((msg: any) =>
            msg.id === payload.id ? payload : msg
          ),
        ],
      };
    case UPLOAD_FILE:
      return {
        ...state,
        newTemplateMedia: payload,
      };
    case LOADING_MEDIA:
      return {
        ...state,
        loadingMedia: payload,
      };
    case LOADING_TEMPLATES:
      return {
        ...state,
        loadingTemplates: payload,
      };
    default:
      return state;
  }
}
