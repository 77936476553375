export const CONDITION = "Condition";
export const IF_CONDITION = "if condition";
export const TIME_CONDITION = "Time Condition";
export const END_CONDITION = "end Condition";

export const START = "Start";
export const ADD_STEP = "Add Step";
export const REPLIED = "Replied";
export const NOT_REPLIED = "Not Replied";

export const REPLY = "reply";
export const WAIT = "wait";
export const JUMP = "jump";
export const WAIT_RESPONSE = "waitRes";
export const END_FLOW = "end";
export const TRANSFER = "transfer";

export const REPORT = "report";

export const HTTP_REQUEST = "HTTP Request";
export const AWAIT_WEBHOOK = "Await webhook";
export const RECEIVED= "Received";
export const EXPIRED = "Expired";
export const SUCCESS = "Success";
export const FAILED = "Failed";
export const WRONG_ANSWER = "Wrong answer";
export const TIMEOUT = "Timeout";
//data processing nodes types
export const SET_VARIABLES = "setVars";
export const MAP = "Map";
export const END_MAP = "End Map";
export const FILTER = "Filter";
export const FORMAT = "format";
export const SORT = "sort";
export const END_HTTP = "End Event";
export const END_WAIT_RES = "End Event";
export const GROUP_BY = "grouped by";

export const DETECT_LANGUAGE = "detect language";
export const SUB_FLOW = "sub flow";
export const GROUP = "group";
export const WHATSAPP = "whatsApp";
export const WH_FLOWS = "Whatsapp flow";
