import {
  CREATE_FLOW,
  DELETE_FLOW,
  DEPRECATE_FLOW,
  GENERATE_PREVIEW,
  GET_FLOWS,
  LOADING_FLOWS,
  PUBLISH_FLOW,
  UPDATE_CURRENT_FLOW,
  UPDATE_FLOW,
} from "./types";

interface IntialState {
  flows: [];
  currentFlow: {} | null;
  loadingFlows: boolean;
}

const initialState: IntialState = {
  flows: [],
  currentFlow: null,
  loadingFlows: false,
};

export default function whFlowsReducer(
  state = initialState,
  action: { type: any; payload: any }
) {
  const { type, payload } = action;
  switch (type) {
    case GET_FLOWS:
      return {
        ...state,
        flows: [...payload],
      };
    case CREATE_FLOW:
      return {
        ...state,
        flows: [...state.flows, payload],
      };
    case UPDATE_FLOW:
      const updatedFlows = state.flows.map((flow: any) =>
        flow.id === payload.id ? { ...flow, ...payload } : flow
      );
      return {
        ...state,
        flows: updatedFlows,
      };
    case DELETE_FLOW:
      const newFlows = state.flows.filter((flow: any) => flow.id !== payload);
      return {
        ...state,
        flows: newFlows,
      };
    case PUBLISH_FLOW:
      return {
        ...state,
      };
    case DEPRECATE_FLOW:
      console.log({ deprecate: payload });
      return {
        ...state,
      };
    case GENERATE_PREVIEW:
      return {
        ...state,
        flows: state.flows.map((flow: any) =>
          flow.id === payload.id ? { ...flow, ...payload } : flow
        ),
      };
    case UPDATE_CURRENT_FLOW:
      return {
        ...state,
        currentFlow: payload,
      };
    case LOADING_FLOWS:
      return {
        ...state,
        loadingFlows: payload,
      };
    default:
      return state;
  }
}
