import * as types from "components/flow/flow-editor/tabPanelFlowEditor/NodeTypes";
import { timeType, typeOperation, typeVariable } from "enums/flow";
import { findIndex, has, keys, remove, uniqueId } from "lodash";
import moment from "moment";
import { updateErrorsFieldsReply } from "redux/flow/actions";
import store from "redux/store";

export const checkPermissions = (profile: any, permissions: string) => {
  if (has(profile, "permissions") && profile !== undefined) {
    return (profile?.permissions).includes(permissions);
  }
  return false;
};

export const convertSecToMin = (sec: number) => {
  return moment.duration(sec, "seconds").asMinutes();
};
export const convertMinToSec = (min: number) => {
  return moment.duration(min, "minutes").asSeconds();
};

export const checkTabPermissions = (profile: any, checkedTab: string) => {
  if (has(profile, "tabPermissions") && profile !== undefined) {
    /* if(profile?.tabPermissions?.length === 0 || profile?.tabPermissions === null) {
      return true;
    } */
    return profile?.tabPermissions?.includes(checkedTab);
  }
  return false;
};

export const calculateNumberPages = (
  totalNumberItems: number,
  numberItemsInPage = 10
) => {
  var numberPages: number = Math.floor(totalNumberItems / numberItemsInPage);
  if (totalNumberItems % numberItemsInPage !== 0) {
    numberPages += 1;
  }
  return numberPages;
};

export const validateEmail = (email: string) => {
  let rgx =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return rgx.test(email);
};

export const downloadFileCSV = (fileData: string) => {
  let blob = new Blob([fileData], { type: "text/csv;charset=utf-8;" });
  let url = URL.createObjectURL(blob);
  let a = document.createElement("a");
  a.href = url;
  a.download = "Campaign details";
  document.body.appendChild(a);
  a.click();
  a.remove();
};

export const formatAsPercent = (num: number) => {
  return (Number(Number(num).toFixed(2)) / 100).toLocaleString(undefined, {
    style: "percent",
    minimumFractionDigits: 2,
    maximumFractionDigits: 4,
  });
};

export const currencyFormatter = (value?: string | number, code?: string) => {
  return new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: code,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(Number(Number(value).toFixed(2)));
};

export const nFormatter = (num: number) => {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "G";
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  }
  return process.env.REACT_APP_ENV === "cppa"
    ? num
    : typeof num === "string"
    ? num
    : num?.toFixed(2);
};

export const formatTime = (item: any) => {
  let d = moment.duration(item).days();
  let m = moment.duration(item).minutes();
  let s = moment.duration(item).seconds();
  let h = moment.duration(item).hours();
  let res = [
    { id: "d", value: d },
    { id: "h", value: h },
    { id: "m", value: m },
    { id: "s", value: s },
  ];
  let newRes = res.filter((item) => item.value !== 0);

  if (newRes.filter((item) => item.id !== "s")?.length > 1) {
    remove(newRes, { id: "s" });
  }
  if (newRes?.length === 0) {
    return "-";
  }
  let val = "";
  newRes.map((item) => (val += `${item.value}${item.id} `));
  return val;
};

export const formatTimeAsDigital = (item: any) => {
  let h = moment.duration(item).hours();
  let res = [{ id: "h", value: h }];
  let newRes = res.filter((item) => item.value !== 0);

  if (newRes?.length === 0) {
    return "-";
  }
  let val = "";
  newRes.map((item) => (val += `${item.value}:00`));
  return val;
};

export const timeConvert = (
  sec: number,
  visibleSecond: boolean = true,
  useNumberFormale: boolean = false,
  visibleZero: boolean = false,
  isUsedFloor: boolean = true
) => {
  if (isNaN(sec) || sec < 0) {
    return "";
  }
  let hConvert: number = (sec / 60 / 60) as number;
  let h = Math.floor(hConvert);
  let mConvert = (hConvert - h) * 60;
  let m = Math.floor(mConvert);
  let sConvert = (mConvert - m) * 60;
  let s = Math.floor(sConvert);
  let res = "";
  if (h > 0 || visibleZero) {
    res += useNumberFormale ? h : `${h}h `;
  }
  if (m > 0 || (mConvert>0 && !isUsedFloor)) {
    let resM: string | number = m;
    if (!useNumberFormale) {
      resM = `${m}m `;
      if (!isUsedFloor) {
        resM = `${(sec - (h * 60 * 60)) / 60}m `;
      }
    }
    res += resM;
  }
  if (visibleSecond && s > 0) {
    res += useNumberFormale ? s : `${s}s `;
  }
  return res;
};

export const secondsToHMS = (seconds: number): string => {
  if (isNaN(seconds) || seconds < 0) {
    return "00:00:00";
  }
  const hours: number = Math.floor(seconds / 3600);
  const minutes: number = Math.floor((seconds % 3600) / 60);
  const remainingSeconds: number = seconds % 60;

  return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
    2,
    "0"
  )}:${String(remainingSeconds).padStart(2, "0")}`;
};

export const convertToNanoSecond: (type: string, value: number) => number = (
  type,
  value
) => {
  if (type === timeType.seconds) {
    return value * 1000000000;
  }
  if (type === timeType.minutes) {
    return value * 60 * 1000000000;
  }
  if (type === timeType.hours) {
    return value * 60 * 60 * 1000000000;
  }
  if (type === timeType.days) {
    return value * 24 * 60 * 60 * 1000000000;
  }
  if (type === timeType.months) {
    return value * 30 * 24 * 60 * 60 * 1000000000;
  }
  return 0;
};

export const downloadObjectAsJson = (exportObj: object, exportName: string) => {
  var dataStr =
    "data:text/json;charset=utf-8," +
    encodeURIComponent(JSON.stringify(exportObj));
  var downloadAnchorNode = document.createElement("a");
  downloadAnchorNode.setAttribute("href", dataStr);
  downloadAnchorNode.setAttribute("download", exportName + ".json");
  document.body.appendChild(downloadAnchorNode); // required for firefox
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
};

export const generateIdFlow = () => {
  return uniqueId(`${new Date().getTime()}`);
};

export const IframeCheck: () => string | null = () => {
  return localStorage.getItem("search");
};

export const replaceWithBr = (textMsg: string) => {
  return textMsg.replace(/\n/g, "<br />");
};

/**
 * the function returns the index of item found in object
 * @param items
 * @param key
 * @returns
 */
export const calculateIndexItem = (items = {}, key: string) => {
  return findIndex(keys(items), (e) => e === key);
};

export const printConsole = (msg: any) => {
  return process.env.NODE_ENV === "development" ? console.log(msg) : undefined;
};

export function checkTimeDifferencesInDays(
  oldDate: string,
  newDate: string
): boolean {
  const date1 = new Date(oldDate).setHours(0, 0, 0, 0);
  const date2 = new Date(newDate).setHours(0, 0, 0, 0);

  return date1 !== date2;
}

export const replaceVarsOfPredefine = (message: string, clientName: string) => {
  let result = message;
  const profile = store.getState().auth.profile;
  //<agentEmail><companyName><roleName><agentName><clientName>
  return result
    .replaceAll("<agentEmail>", `${profile.agentKey} `)
    .replaceAll("<companyName>", `${profile.company} `)
    .replaceAll("<roleName>", `${profile.role} `)
    .replaceAll("<agentName>", `${profile.name} `)
    .replaceAll("<clientName>", `${clientName} `);
};

export const downloadFiles = (url: string) => {
  const a: any = document.createElement("a");
  a.href = url;
  a.download = url.split("/").pop();
  a.target = "_blank";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const isNumericRegex = (value: string) => {
  return /^-?\d+$/.test(value);
};
export const isBooleanRegex = (value: string) => {
  // Regular expression to test if the value is a boolean
  const booleanRegex = /^(true|false)$/i;

  // Test the value against the regular expression
  return booleanRegex.test(String(value));
};
export const isRegexVars = (value: string) => {
  return new RegExp("^[^-]+$").test(value);
};

export const isContainSpace = (value: string) => {
  return /\s/.test(value);
};

// export const handleErrorMsgReplyNode=(type:string, msg:string="", nodeId:string,activeMessageSettings:string)=>{
//   let obj:any={
//     type,
//     nodeId,
//     attr:"text",
//     activeMessageSettings,
//     typeNode:"reply"
//   }
//   if(type==="add"){
//     obj["msg"]=msg
//   }
//   store.dispatch(updateErrorsFields(obj))
// }

export const handleErrorMsgReplyNode = (
  type: string,
  nodeId: string,
  activeMessageSettings: string,
  errorData: any,
  typeError: string = "text",
  msgError: string = "error_field",
  t: any = () => {}
) => {
  let errorDataNew = { ...errorData };
  if (type === typeOperation.DELETE) {
    delete errorDataNew?.[typeError];
    if (activeMessageSettings === types.SORT) {
      errorDataNew = {
        ...(errorDataNew || {}),
        variables: t(`error_add_variables_sort`),
      };
    }
    if (activeMessageSettings === types.GROUP_BY) {
      errorDataNew = {
        ...(errorDataNew || {}),
        variables: t(`error_add_variables_groupby`),
      };
    }
  }
  if (type === typeOperation.ADD) {
    errorDataNew = {
      ...errorDataNew,
      [typeError]: msgError,
    };
  }
  let obj: any = {
    nodeId,
    activeMessageSettings,
    data: errorDataNew,
  };
  store.dispatch(updateErrorsFieldsReply(obj));
};

export const parseLink = (text: string) => {
  const urlRegex =
    /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;

  return text.replace(urlRegex, (url) => {
    let hyperlink = url;
    if (!hyperlink.match("^https?://")) {
      hyperlink = "http://" + hyperlink;
    }
    return `<a  href=${url} target="_blank" rel="noreferrer">${url}</a>`;
  });
};

export const calculateOffest = (isMinus: boolean = false) => {
  let signal = "+";
  if (isMinus) {
    signal = "-";
  }
  let offset = `${signal}03:30`;
  if (new Date().getTimezoneOffset() > 0) {
    offset = "";
  }
  return offset;
};

export const handleShiftTime = (time: number) => {
  let value = "";
  value = moment
    .utc((time + moment().utcOffset()) * 60 * 1000)
    .format("HH:mm:ss");
  // value=moment.utc((time+(-360))*60*1000).format('HH:mm:ss')
  return timeConvert(
    moment.duration(value).asSeconds(),
    false,
    false,
    true,
    false
  );
};

export const isArabic = (value: string) => {
  var arabic = /[\u0600-\u06FF]/;
  return arabic.test(value);
};

export const validationMediaType = (format: string, url: string) => {
  let error = false;
  if (format === "image" || format === "IMAGE") {
    if (!url.match(/([a-z\-_0-9/:.]*\.(jpg|jpeg|png|jif|pjpeg|jfi))/i)) {
      error = true;
    }
  } else if (format === "video" || format === "VIDEO") {
    if (!url.match(/([a-z\-_0-9/:.]*\.(mp4|m4v))/i)) {
      error = true;
    }
  } else {
    if (!url.match(/([a-z\-_0-9/:.]*\.(pdf))/i)) {
      error = true;
    }
  }
  return error;
};

export const hexToRgb = (hex: string, a: number) => {
  // Remove the '#' symbol, if present
  hex = hex.replace(/^#/, "");

  // Parse the hex value into separate RGB components
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return `rgb(${r}, ${g}, ${b},${a})`;
};

export const onExportTypeValue = (
  value: string,
  selectedType?: typeVariable
) => {
  let type = typeVariable.STRING;

  if (selectedType) {
    switch (selectedType) {
      case typeVariable.STRING:
        type = typeVariable.STRING;
        break;
      case typeVariable.NUMBER:
        if (!/"/.test(value) && isNumericRegex(value)) {
          type = typeVariable.NUMBER;
        }
        break;
      case typeVariable.BOOLEAN:
        if (!/"/.test(value) && isBooleanRegex(value)) {
          type = typeVariable.BOOLEAN;
        }
        break;

      default:
        type = typeVariable.STRING;
    }
  } else {
    if (!/"/.test(value) && isNumericRegex(value)) {
      type = typeVariable.NUMBER;
    }
    if (!/"/.test(value) && isBooleanRegex(value)) {
      type = typeVariable.BOOLEAN;
    }
  }
  return type;
};

export const isArrayOfNumbers = (str: string) => {
  // Check if the string starts with '[' and ends with ']'
  if (!str.startsWith("[") || !str.endsWith("]")) {
    return false;
  }

  try {
    // Parse the string to an array
    const arr = JSON.parse(str);

    // Check if every element is a number
    return Array.isArray(arr) && arr.every((el) => typeof el === "number");
  } catch (error) {
    return false; // Parsing failed
  }
};

// Function to check if a string represents an array of strings
export const isArrayOfStrings = (str: string) => {
  // Check if the string starts with '[' and ends with ']'
  if (!str.startsWith("[") || !str.endsWith("]")) {
    return false;
  }

  try {
    // Parse the string to an array
    const arr = JSON.parse(str);

    // Check if every element is a string
    return Array.isArray(arr) && arr.every((el) => typeof el === "string");
  } catch (error) {
    return false; // Parsing failed
  }
};

export const isArrayOfBoolean = (str: string) => {
  // Check if the string starts with '[' and ends with ']'
  if (!str.startsWith("[") || !str.endsWith("]")) {
    return false;
  }

  try {
    // Parse the string to an array
    const arr = JSON.parse(str);

    // Check if every element is a boolean
    return Array.isArray(arr) && arr.every((el) => typeof el === "boolean");
  } catch (error) {
    return false; // Parsing failed
  }
};

export const isValidURL = (url: string) => {
  const regex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
  return regex.test(url);
};

// Whatsapp Supported Media Types
const imageTypes = ["image/jpeg", "image/png"];
const videoTypes = ["video/3gp", "video/mp4"];
const audioTypes = [
  "audio/aac",
  "audio/amr",
  "audio/mpeg",
  "audio/mp4",
  "audio/ogg",
];
const documentTypes = [
  "text/plain",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/pdf",
];

// Whatsapp media size limits
export const mediaSizeLimits: Record<string, number> = {
  image: 5 * 1024 * 1024,
  video: 16 * 1024 * 1024,
  document: 100 * 1024 * 1024,
  audio: 16 * 1024 * 1024,
};

// get the media type based on MIME type supported by Whatsapp
export const getMediaType = (MIMEType: string): string | undefined => {
  if (imageTypes.includes(MIMEType)) return "image";
  if (videoTypes.includes(MIMEType)) return "video";
  if (audioTypes.includes(MIMEType)) return "audio";
  if (documentTypes.includes(MIMEType)) return "document";

  return undefined;
};
