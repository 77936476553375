import { combineReducers } from "redux";
import accountReducer from "./account/reducer";
import agentReducer from "./agent/reducer";
import alertReducer from "./appSetting/reducer";
import authReducer from "./auth/reducer";
import campaignReducer from "./campaign/reducer";
import channelsReducer from "./channels/reducer";
import chatReducer from "./chat/reducer";
import communityReducer from "./community/reducer";
import companySettingReducer from "./company/reducer";
import dashboardReducer from "./dashboard/reducer";
import flowReducer from "./flow/reducer";
import groupReducer from "./group/reducer";
import integrationsReducer from "./integrations/reducer";
import kpiReducer from "./kpi/reducer";
import notesReducer from "./notes/reducer";
import predefineReducer from "./predefine/reducer";
import reportsReducer from "./reports/reducer";
import requestReducer from "./request/reducer";
import securityReducer from "./security/reducer";
import sessionReducer from "./session/reducer";
import subscribeReducer from "./subscribe/reducer";
import surveyReducer from "./survey/reducer";
import tagsReducer from "./tags/reducer";
import templatesReducer from "./templates/reducer";
import whFlowsReducer from "./whFlows/reducer";

const appReducer: any = combineReducers({
  account: accountReducer,
  agent: agentReducer,
  auth: authReducer,
  campaign: campaignReducer,
  channels: channelsReducer,
  chat: chatReducer,
  community: communityReducer,
  companySetting: companySettingReducer,
  config: alertReducer,
  dashboard: dashboardReducer,
  flow: flowReducer,
  group: groupReducer,
  kpi: kpiReducer,
  notes: notesReducer,
  preDefine: predefineReducer,
  reports: reportsReducer,
  requests: requestReducer,
  sessions: sessionReducer,
  subscribe: subscribeReducer,
  survey: surveyReducer,
  tags: tagsReducer,
  templates: templatesReducer,
  integrations: integrationsReducer,
  security: securityReducer,
  whFlows: whFlowsReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === "USER_LOGOUT") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};
export default rootReducer;
