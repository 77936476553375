import { Preferences } from "redux/auth/selector";
import http, { signal } from "../config/httpComman";

interface userAuth {
  username: string;
  password: string;
  remember: boolean;
  deviceFingerprint: string;
}

export interface signUp {
  adminEmail: string;
  adminPassword: string;
  companyName: string;
  planID: number;
  agreeTerm?: boolean;
}

export interface verifySignUp {
  email: string;
  code: string;
}
export default class authService {
  static async postLogin(user: userAuth) {
    return await http.post("/login", user);
  }

  static async getProfile() {
    return await http.get("/profile", signal);
  }

  static async updatePreferences(data: { preferences: Preferences }) {
    return await http.post("/profile", data, signal);
  }

  static async signUp(data: signUp) {
    return await http.post("/signUp", data);
  }

  static async verifySignup(data: verifySignUp) {
    return await http.post("/verifyEmail", data);
  }

  static async forgetPassword(email: string) {
    return await http.post("/forgotPassword", { email });
  }

  static async resetPassword(data: { password: string; resetKey: string }) {
    return await http.post("/resetPassword ", data);
  }

  static async refreshToken(data: { token: string }) {
    return await http.post("/auth/refresh", data);
  }

  static async validateTwoFactor(data: { code: string }) {
    return await http.post("/oauth/code/verify", data);
  }

  static async resetTwoFactor(data: { code: string }) {
    return await http.post("/oauth/code/verify", data);
  }

  static async solveCaptcha(data: { captcha: string; x: number; y: number }) {
    return await http.post("/captcha", data);
  }

  static async refreshCaptcha() {
    return await http.get("/captcha");
  }
}
