import { Captcha, Profile } from "./selector";
import {
  ADD_TOKEN,
  ASK_FOR_OTP,
  DISABLE_CURRENT_TAB_SESSION,
  GET_PROFILE,
  LOADING_LOGIN,
  LOGIN_STATUS,
  OTHER_LOGOUT,
  RESET_REGIST,
  UPDATE_OTP_OPTION,
  UPDATE_PROFILE_PREFERENCES,
  UPDATE_PROFILE_STATUE,
  UPDATE_PROFILE_STATUE_COMMUNITY,
  USER_SIGNUP,
  USER_VERIFY,
  SET_CAPTCHA,
  CLEAR_CAPTCHA,
  SET_SOLVED_CAPTCHA_ID,
} from "./types";

interface IntialState {
  loginStatue: {};
  socketId: string;
  profile: Profile | {};
  token: string;
  sigup: boolean;
  showOTP: boolean;
  verified: boolean;
  verifyEmail: string;
  anotherSignOut: boolean;
  relogin: boolean;
  profileStatus: boolean;
  communityStatus: string;
  isLoadingLogin: boolean;
  customStatus: number;
  otpOption: {
    twoFactorCodeLength: number;
    twoFactorCodeExpiration: number; // in seconds
    sessionID: string;
  };
  captcha?: Captcha;
  solvedCaptchaId: string | null;
}

const initialState: IntialState = {
  loginStatue: {},
  socketId: "",
  profile: {},
  token: "",
  sigup: false,
  showOTP: false,
  verified: false,
  verifyEmail: "",
  anotherSignOut: false,
  relogin: false,
  profileStatus: true,
  communityStatus: "",
  isLoadingLogin: false,
  customStatus: 0,
  otpOption: {
    twoFactorCodeLength: 8,
    sessionID: "",
    twoFactorCodeExpiration: 300, // in seconds
  },
  captcha: undefined,
  solvedCaptchaId: null,
};

export default function authReducer(
  state = initialState,
  action: { type: any; payload: any }
) {
  const { type, payload } = action;
  switch (type) {
    case LOGIN_STATUS:
      return {
        ...state,
        loginStatue: payload.loginStatus,
        socketId: payload.socketId,
      };
    case GET_PROFILE:
      return {
        ...state,
        profile: payload.profile,
        profilePreferences: payload.profile.preferences,
        profileStatus: payload.profile.visibility !== 2 ? false : true,
        communityStatus: payload.profile.communityStatus,
        customStatus: payload.profile.customStatus,
      };
    case UPDATE_PROFILE_STATUE_COMMUNITY:
      return {
        ...state,
        communityStatus: payload,
      };
    case ADD_TOKEN:
      return {
        ...state,
        token: payload.token,
      };
    case USER_SIGNUP:
      return {
        ...state,
        sigup: true,
        verifyEmail: payload.adminEmail,
      };

    case USER_VERIFY:
      return {
        ...state,
        verified: true,
      };
    case RESET_REGIST:
      return {
        ...state,
        sigup: false,
        verifyEmail: "",
      };
    case OTHER_LOGOUT:
      return {
        ...state,
        anotherSignOut: true,
      };
    case DISABLE_CURRENT_TAB_SESSION:
      return {
        ...state,
        relogin: true,
      };
    case UPDATE_PROFILE_STATUE:
      return {
        ...state,
        profileStatus: payload.visibility,
        customStatus: payload.customStatus,
      };
    case UPDATE_PROFILE_PREFERENCES:
      return {
        ...state,
        profilePreferences: payload,
      };
    case LOADING_LOGIN:
      return {
        ...state,
        isLoadingLogin: payload,
      };
    case ASK_FOR_OTP:
      return {
        ...state,
        showOTP: payload,
      };
    case UPDATE_OTP_OPTION:
      return {
        ...state,
        showOTP: payload.showOTP,
        otpOption: payload.otpOption,
      };
    case SET_CAPTCHA:
      return {
        ...state,
        captcha: payload,
      };
    case CLEAR_CAPTCHA:
      return {
        ...state,
        captcha: undefined,
      };
    case SET_SOLVED_CAPTCHA_ID:
      return {
        ...state,
        solvedCaptchaId: payload,
      };
    default:
      return state;
  }
}
