import { AxiosResponse } from "axios";
import { socket } from "socketConfig/socketConnect";
import authService, { signUp, verifySignUp } from "../../services/auth.service";
import securityService from "../../services/security.service";
import {
  authenticationEvent,
  changeProfileStatus,
  loginStatus,
  logoutEvent,
  logoutNotification,
  profileStatus,
  reloginEvent,
  updateAgentCommunityStatues,
  updateAgentCommunityVisibility,
} from "../../socketConfig/socketEvent";
import localize from "../../utils/localize";
import { loading, stopLoading, triggerAlert } from "../appSetting/actions";
import {
  ADD_TOKEN,
  ASK_FOR_OTP,
  CLEAR_CAPTCHA,
  DISABLE_CURRENT_TAB_SESSION,
  FORGET_PASSWORD,
  GET_PROFILE,
  LOADING_LOGIN,
  LOGIN_STATUS,
  OTHER_LOGOUT,
  RESEND_OTP,
  RESET_PASSWORD,
  RESET_REGIST,
  SET_CAPTCHA,
  SET_SOLVED_CAPTCHA_ID,
  UPDATE_OTP_OPTION,
  UPDATE_PROFILE_PREFERENCES,
  UPDATE_PROFILE_STATUE,
  UPDATE_PROFILE_STATUE_COMMUNITY,
  USER_LOGOUT,
  USER_SIGNUP,
  USER_VERIFY,
} from "./types";
// import { toString } from "lodash";
import { controller } from "config/httpComman";
import { has } from "lodash";
import { errorHandling } from "utils/errorHandling";
import { Preferences } from "./selector";
// import { controller } from "App";

const initialInboxNotificationPreference = {
  active: {
    sound: true,
    desktop: true,
  },
  inactive: {
    sound: false,
    desktop: false,
  },
  escalated: {
    sound: true,
    desktop: true,
  },
  escalatedUnassign: {
    sound: false,
    desktop: false,
  },
  transferAgent: {
    sound: false,
    desktop: false,
  },
  transferSuperAgent: {
    sound: false,
    desktop: false,
  },
};
const initialCommunityNotificationPreference = {
  channel: true,
  group: true,
  direct: true,
};

export const loginSocketConfirm = (jwt: string) => async (dispatch: any) => {
  try {
    authenticationEvent(jwt);

    // check loginEvent
    loginStatus((data: { status: "SUCCESS" }) => {
      if (data.status === "SUCCESS") {
        localStorage.setItem("socketId", socket.id);
        dispatch(addSocketId(data));
        return;
      } else {
        socket.disconnect(true);
        dispatch(triggerAlert(localize("login_failed"), "error"));
      }
    });
  } catch (error) {
    console.log({ loadSessionsError: error });
  }
};

export const addSocketId =
  (data: { status: "SUCCESS" }) => async (dispatch: any) => {
    try {
      if (data.status === "SUCCESS") {
        dispatch({
          type: LOGIN_STATUS,
          payload: {
            loginStatus: data,
            socketId: socket.id ?? localStorage.getItem("socketId"),
          },
        });
      }
    } catch (e) {
      dispatch(triggerAlert(localize("logout_failed"), "error"));
    }
  };

export const gettingProfile = () => async (dispatch: any) => {
  try {
    const response: AxiosResponse = await authService.getProfile();
    if (response.status === 200) {
      const { preferences: profilePreferences } = response.data;

      dispatch({
        type: GET_PROFILE,
        payload: { profile: response.data },
      });

      //incase the profile doesn't have notifications preferences, set the default preferences
      if (
        !profilePreferences ||
        !has(profilePreferences, "notifications") ||
        !has(profilePreferences, "notifications.inbox") ||
        !has(profilePreferences, "notifications.community")
      ) {
        dispatch(
          updateProfilePreference({
            ...(profilePreferences || {}),
            notifications: {
              inbox: initialInboxNotificationPreference,
              community: initialCommunityNotificationPreference,
              ...(profilePreferences?.notifications || {}),
            },
          })
        );
      }
    }
  } catch (er) {
    errorHandling(er, dispatch, "login_failed");
  }
};

export const login = (user: any) => async (dispatch: any) => {
  try {
    dispatch(updateLoadingLogin(true));
    const response: AxiosResponse = await authService.postLogin(user);
    if (response.status === 200 && response?.data?.hasOwnProperty("token")) {
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("sessionID", response.data.sessionID);
      localStorage.setItem("refreshToken", response.data.rememberMeToken);
      dispatch(addToken());
      dispatch(clearCaptcha());
      dispatch(updateLoadingLogin(false));
      return response;
    } else if (response?.data?.hasOwnProperty("thumb")) {
      dispatch({
        type: SET_CAPTCHA,
        payload: response.data,
      });
      dispatch(triggerAlert("Captcha verification required", "info"));
    } else if (response?.data?.hasOwnProperty("twoFactorAuthentication")) {
      dispatch(triggerAlert("Two-factor authentication required", "info"));
      localStorage.setItem("sessionID", response.data.sessionID);
      dispatch({
        type: UPDATE_OTP_OPTION,
        payload: {
          showOTP: response.data.twoFactorAuthentication,
          otpOption: {
            twoFactorCodeLength: response.data.twoFactorCodeLength,
            //TODO: add more options like expiry time
            sessionID: response.data.sessionID,
            twoFactorCodeExpiration: response.data.twoFactorCodeExpiration,
          },
        },
      });
      dispatch({ type: ASK_FOR_OTP, payload: true });
    } else {
      dispatch(
        triggerAlert(response?.data?.failMsg?.toString() || "", "error")
      );
    }
    dispatch(updateLoadingLogin(false));
  } catch (e) {
    const errorMsg =
      (e as any).response?.data?.failMsg || localize("logout_failed");
    dispatch(triggerAlert(errorMsg, "error"));
    dispatch(updateLoadingLogin(false));
  }
};

export const clearCaptcha = () => (dispatch: any) => {
  dispatch({
    type: CLEAR_CAPTCHA,
  });
};

export const twoFactorAuthentication = (data: any) => async (dispatch: any) => {
  try {
    dispatch(updateLoadingLogin(true));
    const response: AxiosResponse = await authService.validateTwoFactor(data);
    if (response.status === 200 && response?.data?.hasOwnProperty("token")) {
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("sessionID", response.data.sessionID);
      localStorage.setItem("refreshToken", response.data.rememberMeToken);
      dispatch(addToken());
      dispatch(updateLoadingLogin(false));
      dispatch({
        type: "ASK_FOR_OTP",
        payload: false,
      });
      return response;
    } else {
      dispatch(
        triggerAlert(response?.data?.failMsg?.toString() || "", "error")
      );
    }
    dispatch(updateLoadingLogin(false));
  } catch (e) {
    const errorMsg =
      (e as any).response?.data?.failMsg || localize("logout_failed");
    dispatch(triggerAlert(errorMsg, "error"));
    dispatch(updateLoadingLogin(false));
  }
};

export const resetTwoFactorAuthentication =
  (data: any) => async (dispatch: any) => {
    try {
      dispatch(updateLoadingLogin(true));
      const response: AxiosResponse = await authService.resetTwoFactor(data);
      if (response.status === 200 && response?.data?.hasOwnProperty("token")) {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("refreshToken", response.data.rememberMeToken);
        dispatch(addToken());
        dispatch(updateLoadingLogin(false));

        return response;
      } else {
        dispatch(triggerAlert(response?.data?.toString() || "", "error"));
      }
      dispatch(updateLoadingLogin(false));
    } catch (e) {
      const errorMsg =
        (e as any).response?.data?.failMsg || localize("logout_failed");
      dispatch(triggerAlert(errorMsg, "error"));
      dispatch(updateLoadingLogin(false));
    }
  };

export const addToken = () => async (dispatch: any) => {
  try {
    dispatch({
      type: ADD_TOKEN,
      payload: { token: localStorage.getItem("token") },
    });
  } catch (e) {
    dispatch(triggerAlert(localize("logout_failed"), "error"));
  }
};

export const logoutTabInstances = () => async (dispatch: any) => {
  try {
    logoutNotification(() => {
      dispatch({
        type: OTHER_LOGOUT,
      });
    });
  } catch (error) {
    console.log({ loadSessionsError: error });
  }
};
export const logout = (token: string) => async (dispatch: any) => {
  try {
    // console.log('logout')
    localStorage.removeItem("token");
    localStorage.removeItem("socketId");
    localStorage.removeItem("sessionID");
    logoutEvent();
    socket.disconnect(true);
    dispatch({
      type: USER_LOGOUT,
    });
    controller.abort();
    window.location.reload();
    /* 
    --- this code will temporary will not be used until auth 2 goes to prod ---
    await securityService
      .revokeSessionActiveAgent({
        session_ids: [parseInt(localStorage.getItem("sessionID") ?? "0", 10)],
      })
      .then(() => {
        localStorage.removeItem("token");
        localStorage.removeItem("socketId");
        localStorage.removeItem("sessionID");
        logoutEvent();
        socket.disconnect(true);
        dispatch({
          type: USER_LOGOUT,
        });
        controller.abort();
        window.location.reload();
      }); */
    // localStorage.setItem('location','/');
  } catch (e) {
    dispatch(triggerAlert(localize("logout_failed"), "error"));
  }
};

export const reloginAction = () => async (dispatch: any) => {
  try {
    reloginEvent((data: { status: "SUCCESS" }) => {
      if (data.status === "SUCCESS") {
        dispatch({
          type: DISABLE_CURRENT_TAB_SESSION,
        });
        localStorage.removeItem("socketId");
        socket.disconnect(true);
        controller.abort();
      }
    });
  } catch (e) {
    dispatch(triggerAlert(localize("logout_failed"), "error"));
  }
};

export const updatingProfileStatus =
  (status: boolean, customStatus: number) => async (dispatch: any) => {
    try {
      await changeProfileStatus(status, customStatus);
    } catch (er) {
      errorHandling(er, dispatch, "agent_status_failed");
    } finally {
      dispatch(stopLoading());
    }
  };

export const updatingProfileStatusCommunity =
  (status: string) => async (dispatch: any) => {
    try {
      await updateAgentCommunityStatues(status);
      dispatch({
        type: UPDATE_PROFILE_STATUE_COMMUNITY,
        payload: status,
      });
    } catch (er) {
      errorHandling(er, dispatch, "agent_status_failed");
    }
  };

export const updateProfileVisibilityCommunity =
  (status: boolean) => async (dispatch: any) => {
    try {
      updateAgentCommunityVisibility(status);
    } catch (er) {
      errorHandling(er, dispatch, "agent_status_failed");
    } finally {
      dispatch(stopLoading());
    }
  };

export const updateProfileStatus = () => async (dispatch: any) => {
  try {
    profileStatus(
      (data: {
        message: string;
        status: string;
        visibility: boolean;
        customStatus: number;
      }) => {
        if (data.status === "SUCCESS") {
          dispatch({
            type: UPDATE_PROFILE_STATUE,
            payload: {
              visibility: !data.visibility,
              customStatus: data.customStatus,
            },
          });
        }
      }
    );
  } catch (er) {
    errorHandling(er, dispatch, "agent_status_failed");
  } finally {
    dispatch(stopLoading());
  }
};

export const updateProfilePreference =
  (data: Preferences) => async (dispatch: any) => {
    try {
      dispatch(loading());
      dispatch({
        type: UPDATE_PROFILE_PREFERENCES,
        payload: data,
      });

      await authService.updatePreferences({ preferences: data });

      //dispatch(gettingProfile());
      dispatch(triggerAlert(localize("update_profile_success"), "success"));
    } catch (er) {
      errorHandling(er, dispatch, "update_profile_failed");
    } finally {
      dispatch(stopLoading());
    }
  };

export const register = (data: signUp) => async (dispatch: any) => {
  try {
    dispatch(loading());

    await authService.signUp(data);
    dispatch({
      type: USER_SIGNUP,
      payload: data,
    });
    dispatch(triggerAlert(localize("signup_success"), "success"));
  } catch (er) {
    errorHandling(er, dispatch, "signup_failed");
  } finally {
    dispatch(stopLoading());
  }
};

export const resetRegister = () => (dispatch: any) => {
  dispatch({
    type: RESET_REGIST,
  });
};

export const verifyRegister = (data: verifySignUp) => async (dispatch: any) => {
  try {
    dispatch(loading());
    const response: AxiosResponse = await authService.verifySignup(data);
    dispatch({
      type: USER_VERIFY,
      payload: response,
    });
    dispatch(triggerAlert(localize("verify_success"), "success"));
  } catch (er) {
    errorHandling(er, dispatch, "verify_failed");
  } finally {
    dispatch(stopLoading());
  }
};

export const forgetPassword = (email: string) => async (dispatch: any) => {
  try {
    dispatch(loading());
    const response: AxiosResponse = await authService.forgetPassword(email);
    dispatch({
      type: FORGET_PASSWORD,
      payload: response,
    });
    dispatch(triggerAlert(localize("forget_password_success"), "success"));
  } catch (er) {
    errorHandling(er, dispatch, "forget_password_failed");
  } finally {
    dispatch(stopLoading());
  }
};

export const resetPassword =
  (data: { password: string; resetKey: string }) => async (dispatch: any) => {
    try {
      dispatch(loading());
      const response: AxiosResponse = await authService.resetPassword(data);
      dispatch({
        type: RESET_PASSWORD,
        payload: response,
      });
      dispatch(triggerAlert(localize("reset_password_success"), "success"));
      window.location.href = `${process.env.REACT_APP_HOST}`;
    } catch (er) {
      errorHandling(er, dispatch, "reset_password_failed");
    } finally {
      dispatch(stopLoading());
    }
  };

export const updateLoadingLogin =
  (isLoading: boolean) => async (dispatch: any) => {
    try {
      dispatch({
        type: LOADING_LOGIN,
        payload: isLoading,
      });
    } catch (er) {
      errorHandling(er, dispatch, "update_loading_login");
    } finally {
    }
  };

export const resendOtpAction = (email: string) => async (dispatch: any) => {
  try {
    dispatch(updateLoadingLogin(true));

    const response: AxiosResponse = await securityService.resendOtp({
      email,
      sessionID: localStorage.getItem("sessionID"),
    });
    if (response.status === 200) {
      dispatch({
        type: RESEND_OTP,
        payload: { email },
      });
      dispatch(triggerAlert("OTP has been resent to your email", "success"));
    } else {
      dispatch(triggerAlert("Failed to resend OTP", "error"));
    }
    dispatch(updateLoadingLogin(false));
  } catch (e) {
    dispatch(triggerAlert("Failed to resend OTP", "error"));
    dispatch(updateLoadingLogin(false));
  }
};

export const solveCaptcha =
  (data: { captcha: string; x: number; y: number }) =>
  async (dispatch: any) => {
    try {
      dispatch(updateLoadingLogin(true));
      const response: AxiosResponse = await authService.solveCaptcha(data);
      if (response.status === 200) {
        dispatch({
          type: SET_SOLVED_CAPTCHA_ID,
          payload: data.captcha,
        });
        dispatch(clearCaptcha());
        dispatch(updateLoadingLogin(false));
        return response;
      } else {
        dispatch(
          triggerAlert(
            response?.data?.failMsg?.toString() ||
              localize("captcha_solve_error"),
            "error"
          )
        );
        dispatch(refreshCaptcha());
      }
      dispatch(updateLoadingLogin(false));
    } catch (e) {
      if ((e as any).response?.status === 400) {
        dispatch(refreshCaptcha());
      }
      const errorMsg =
        (e as any).response?.data?.failMsg || localize("captcha_failed");
      dispatch(triggerAlert(errorMsg, "error"));
      dispatch(updateLoadingLogin(false));
    }
  };

export const refreshCaptcha = () => async (dispatch: any) => {
  try {
    const response: AxiosResponse = await authService.refreshCaptcha();
    if (response.status === 200) {
      dispatch({
        type: SET_CAPTCHA,
        payload: response.data,
      });
    } else {
      dispatch(triggerAlert("Failed to refresh captcha", "error"));
    }
  } catch (e) {
    dispatch(triggerAlert("Failed to refresh captcha", "error"));
  }
};
